<div class="w3l-bootstrap-header fixed-top">
    <nav class="navbar navbar-expand-lg navbar-light p-2">
      <div class="container">
        <a class="navbar-brand" [routerLink]="['']"></a>
        <!-- if logo is image enable this   --->
      <a class="navbar-brand" href="#index.html">
          <img src="assets/images/pinaklogo.webp" alt="imageAlt" title="Your logo" style="height:60px;" />
      </a> 
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link" [routerLink]="['']">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link"[routerLink]="['about']">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['contact']">Contact</a>
            </li>
          </ul>
          <div class="form-inline">
            <a href="#" class="login mr-4">Log in</a>
            <a href="#" class="btn btn-primary btn-theme">Create Free Account</a>
          </div>
        </div>
      </div>
    </nav>
  </div>